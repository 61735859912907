let rutaLocal = "@/components/ERP/";
rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/erp",
    name: "ERP",
    redirects: "/erp/dashboard",
  },
  {
    path: "/erp/dashboard",
    name: "ERP2Dashboard",
    component: () =>
      import(/* webpackChunkName: "pos2dashboard" */ rutaLocal + "Dashboard"),
  },
  {
    path: "/erp/agenda",
    name: "ERP2agenda",
    component: () =>
      import(/* webpackChunkName: "posagenda" */ rutaLocal + "Agenda/Agenda"),
  },
  {
    path: "/erp/agenda/:id",
    name: "ERP2agendaid",
    component: () =>
      import(/* webpackChunkName: "posagendaid" */ rutaLocal + "Agenda/Evento"),
  },
  {
    path: "/erp/pos",
    name: "ERP2",
    component: () =>
      import(/* webpackChunkName: "poslista2" */ rutaLocal + "POS2/Lista"),
    outlet: "pos",
  },
  {
    path: "/erp/pos/:id",
    name: "ERPID",
    component: () =>
      import(/* webpackChunkName: "posproyecto" */ rutaLocal + "POS2/Proyecto"),
    outlet: "pos",
    props: true,
  },
  {
    path: "/erp/pos/:id/comandero",
    name: "ERPIDcomandero",
    component: () =>
      import(
        /* webpackChunkName: "poscomandero" */ rutaLocal + "POS2/Comandero"
      ),
    outlet: "pos",
    props: true,
  },
  {
    path: "/erp/pos/:id/partida",
    name: "ERPIDpartida",
    component: () =>
      import(/* webpackChunkName: "pospartida" */ rutaLocal + "POS2/Partida"),
    outlet: "pos",
    props: true,
  },
  {
    path: "/erp/pos/:id/propiedades",
    name: "ERPIDpropiedades",
    component: () =>
      import(
        /* webpackChunkName: "pospropiedades" */ rutaLocal + "POS2/Propiedades"
      ),
    outlet: "pos",
    props: true,
  },
  {
    path: "/erp/pos/:id/factura",
    name: "ERPIDfactura",
    component: () =>
      import(/* webpackChunkName: "posfactura" */ rutaLocal + "POS2/Factura"),
    outlet: "pos",
    props: true,
  },
  {
    path: "/erp/pos/:id/cliente",
    name: "ERPIDcliente",
    component: () =>
      import(/* webpackChunkName: "poscliente" */ rutaLocal + "POS2/Cliente"),
    outlet: "pos",
    props: true,
  },
  {
    path: "/erp/pos/:id/preview",
    name: "ERPIDpreview",
    component: () =>
      import(/* webpackChunkName: "pospreview" */ rutaLocal + "POS2/Preview"),
    outlet: "pos",
    props: true,
  },
  {
    path: "/erp/pos/:id/cfdi",
    name: "ERPIDcfdi",
    component: () =>
      import(/* webpackChunkName: "pospreview" */ rutaLocal + "CFDI/Cfdi"),
    outlet: "pos",
    props: true,
  },
  {
    path: "/erp/pos/:id/pago",
    name: "ERPIDpago",
    component: () =>
      import(/* webpackChunkName: "pospago" */ rutaLocal + "POS2/Pago"),
    outlet: "pos",
    props: true,
  },
  {
    path: "/erp/pos/:id/costos",
    name: "ERPIDcostos",
    component: () =>
      import(/* webpackChunkName: "poscostos" */ rutaLocal + "POS2/Costos"),
    outlet: "pos",
    props: true,
  },
  {
    path: "/erp/cotizaciones",
    name: "ERPCotizaciones",
    component: () =>
      import(
        /* webpackChunkName: "poscotizaciones" */ rutaLocal +
          "POS2/Cotizaciones"
      ),
    //outlet: "pos",
    //props: true,
  },
  //
  {
    path: "/erp/facturas",
    name: "ERPIDfacturas",
    component: () =>
      import(/* webpackChunkName: "erpfacturas" */ rutaLocal + "Factura/Lista"),
    props: true,
  },
  {
    path: "/erp/gastos",
    name: "ERPIDgastos",
    component: () =>
      import(/* webpackChunkName: "erpgastos" */ rutaLocal + "Gasto/Lista"),
  },
  {
    path: "/erp/gastos/:id",
    name: "ERPIDgasto",
    component: () =>
      import(/* webpackChunkName: "erpgasto" */ rutaLocal + "Gasto/Gasto"),
    props: true,
  },
  {
    path: "/erp/cuentasgastos",
    name: "ERPCuentasGastos",
    component: () =>
      import(
        /* webpackChunkName: "erpcuentagasto" */ rutaLocal + "Gasto/Cuentas"
      ),
  },
  {
    path: "/erp/gastos/:id/:nombreObjeto/:idObjeto",
    name: "ERPIDgastoprops",
    component: () =>
      import(/* webpackChunkName: "erpgastoprops" */ rutaLocal + "Gasto/Gasto"),
    props: true,
  },
  {
    path: "/erp/reportes",
    redirect: "/erp/reportes/dashboard",
  },
  {
    path: "/erp/reportes/dashboard",
    name: "ERPReportesDashboard",
    component: () =>
      import(
        /* webpackChunkName: "erpreportesdashboard" */ rutaLocal +
          "Reporte/Dashboard"
      ),
    props: true,
  },
  {
    path: "/erp/reportes/avanzados",
    name: "ERPReportesAvanzados",
    component: () =>
      import(
        /* webpackChunkName: "erpreportesavanzados" */ rutaLocal +
          "Reporte/Avanzados"
      ),
    props: true,
  },
  //
  {
    path: "/erp/catalogos",
    name: "ERPCatalogos",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogos" */ rutaLocal + "Catalogos/Catalogos"
      ),
  },
  {
    path: "/erp/catalogos/clientes",
    name: "ERPIDcatalogoclientes",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogoclientes" */ rutaLocal +
          "Catalogos/Clientes"
      ),
  },
  {
    path: "/erp/catalogos/clientes/:id",
    name: "ERPIDcatalogocliente",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogocliente" */ rutaLocal +
          "Catalogos/Cliente"
      ),
    props: true,
  },
  {
    path: "/erp/catalogos/prospectos",
    name: "ERPIDcatalogoprospectos",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogoprospectos" */ rutaLocal +
          "Catalogos/Prospectos"
      ),
  },
  {
    path: "/erp/catalogos/prospectos/:id",
    name: "ERPIDcatalogoprospecto",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogoprospectoid" */ rutaLocal +
          "Catalogos/Prospecto"
      ),
    props: true,
  },
  {
    path: "/erp/catalogos/metodospago",
    name: "ERPIDcatalogometoedospagolista",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogometodospagolista" */ rutaLocal +
          "Catalogos/MetodosPago"
      ),
  },
  {
    path: "/erp/catalogos/metodospago/:id",
    name: "ERPIDcatalogometodopagohandler",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogometodopagohamdler" */ rutaLocal +
          "Catalogos/MetodoPago"
      ),
    props: true,
  },
  {
    path: "/erp/catalogos/proveedores",
    name: "ERPIDcatalogoproveedore",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogoproveedored" */ rutaLocal +
          "Catalogos/Proveedores"
      ),
  },
  {
    path: "/erp/catalogos/proveedores/:id",
    name: "ERPIDcatalogoproveedor",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogoproveedor" */ rutaLocal +
          "Catalogos/Proveedor"
      ),
    props: true,
  },
  {
    path: "/erp/catalogos/lineasprecios",
    name: "ERPcatalogolineasprecios",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogoproductos" */ rutaLocal +
          "Catalogos/LineasPrecios"
      ),
  },
  {
    path: "/erp/catalogos/lineasprecios/:id",
    name: "ERPIDcatalogolineaprecioid",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogoproductos" */ rutaLocal +
          "Catalogos/LineaPrecios"
      ),
    props: true,
  },
  {
    path: "/erp/catalogos/productos",
    name: "ERPIDcatalogoproductos",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogoproductos" */ rutaLocal +
          "Catalogos/Productos"
      ),
  },
  {
    path: "/erp/catalogos/productos/:id",
    name: "ERPIDcatalogoproducto",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogoproducto" */ rutaLocal +
          "Catalogos/Producto"
      ),
    props: true,
  },
  {
    path: "/erp/catalogos/productos/:id/:modo2",
    name: "ERPIDcatalogoprodmodo",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogoprodmodo" */ rutaLocal +
          "Catalogos/Producto"
      ),
    props: true,
  },
  {
    path: "/erp/catalogos/bienes/:tipo",
    name: "ERPIDcatalogopbienes",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogobienes" */ rutaLocal +
          "Catalogos/Productos"
      ),
    props: true,
  },
  {
    path: "/erp/catalogos/familias",
    name: "ERPIDcatalogofamilias",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogofamilias" */ rutaLocal +
          "Catalogos/Familias"
      ),
  },
  {
    path: "/erp/catalogos/familias/:id",
    name: "ERPIDcatalogofamilia",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogofamilia" */ rutaLocal +
          "Catalogos/Familia"
      ),
    props: true,
  },
  {
    path: "/erp/catalogos/agendas",
    name: "ERPIDcatalogoagendas",
    component: () =>
      import(
        /* webpackChunkName: "erpcatalogofamilia" */ rutaLocal +
          "Catalogos/Agendas"
      ),
  },
  //
  {
    path: "/erp/almacen",
    name: "ERPIDalmacen",
    component: () =>
      import(
        /* webpackChunkName: "erpalmacen" */ rutaLocal + "Almacen/Almacen"
      ),
  },
  {
    path: "/erp/almacen/inventario",
    name: "ERPIDalmaceninventario",
    component: () =>
      import(
        /* webpackChunkName: "erpinventario" */ rutaLocal + "Almacen/Inventario"
      ),
  },
  {
    path: "/erp/almacen/inventario/:id",
    name: "ERPIDalmaceninventarioid",
    component: () =>
      import(
        /* webpackChunkName: "erpinventarioid" */ rutaLocal +
          "Almacen/Inventario"
      ),
    props: true,
  },
  {
    path: "/erp/almacen/inventariolista",
    name: "ERPIDalmaceninventariolista",
    component: () =>
      import(
        /* webpackChunkName: "erpinventariolista" */ rutaLocal + "Almacen/Lista"
      ),
    props: true,
  },
  {
    path: "/erp/almacen/existencias",
    name: "ERPIDalmacenexistencias",
    component: () =>
      import(
        /* webpackChunkName: "erpexistencias" */ rutaLocal +
          "Almacen/Existencias"
      ),
    props: true,
  },
  {
    path: "/erp/almacen/historial",
    name: "ERPIDalmacenhistorialproductos",
    component: () =>
      import(
        /* webpackChunkName: "erpalmacenhistorial" */ rutaLocal +
          "Almacen/Productos"
      ),
  },
  {
    path: "/erp/ordenescompra",
    name: "ERPOrdenescompra",
    component: () =>
      import(
        /* webpackChunkName: "erpordenes" */ rutaLocal + "OrdenesCompra/Lista"
      ),
  },
  {
    path: "/erp/ordenescompra/:id",
    name: "ERPOrdencompraId",
    component: () =>
      import(
        /* webpackChunkName: "erpordenporid" */ rutaLocal +
          "OrdenesCompra/Orden"
      ),
    props: true,
  },
  {
    path: "/erp/ordenescompramodal",
    name: "ERPOrdenescompramodal",
    component: () =>
      import(
        /* webpackChunkName: "erpordenesmodal" */ rutaLocal +
          "OrdenesCompra/Lista"
      ),
  },
  {
    path: "/erp/ordenescompramodal/:id",
    name: "ERPOrdencompramodalId",
    component: () =>
      import(
        /* webpackChunkName: "erpordenmodalid" */ rutaLocal +
          "OrdenesCompra/Orden"
      ),
    props: true,
  },
  {
    path: "/erp/catalogos/estados",
    name: "ERPEstadosLista",
    component: () =>
      import(
        /* webpackChunkName: "erpestados" */ rutaLocal + "Catalogos/Estados"
      ),
  },
  {
    path: "/erp/catalogos/almacenes",
    name: "ERPalmacenes",
    component: () =>
      import(
        /* webpackChunkName: "erpalmacenes" */ rutaLocal + "Catalogos/Almacenes"
      ),
  },
  {
    path: "/erp/cortes",
    name: "ERPcorteslista",
    component: () =>
      import(
        /* webpackChunkName: "erpcorteslista" */ rutaLocal + "Cortes/Cortes"
      ),
  },
  {
    path: "/erp/cortes/:id",
    name: "ERPCorteId",
    component: () =>
      import(/* webpackChunkName: "erpcorteid" */ rutaLocal + "Cortes/Corte"),
    props: true,
  },
];
//const e = Api.fetchLocal("empresadb") || {};
const manifiestos = {
  ERP: {
    nombre: "ERP",
    descripcion: "Ventas, facturas, gastos y reportes",
    icono: "erp",
    esPropio: true,
    tarifaDefault: 399,
    permisosPosibles: [
      "Puede crear proyectos",
      "Puede eliminar proyectos",
      "Puede ver proyectos de otros usuarios",
      "Puede ver gastos",
      "Puede gestionar almacenes",
      "Puede ver reportes",
      "Puede editar catálogos",
    ],
    rutaPrincipal: "/erp/dashboard",
    menuArbol: [
      {
        ruta: "/erp/pos",
        label: "Ventas",
        permiso: "Puede crear proyectos",
        icono: "cashOutline",
        rolSugerido: "operador",
        tareaSugerida: "Ventas",
      },
      {
        ruta: "/erp/cotizaciones",
        label: "Cotizaciones",
        permiso: "Puede crear proyectos",
        icono: "peopleCircle",
        rolSugerido: "operador",
        tareaSugerida: "Ventas",
        ocultar: !Api.varGlobal("usarCotizaciones"),
      },
      {
        ruta: "/erp/agenda",
        label: "Agenda",
        permiso: "Puede crear proyectos",
        icono: "calendarOutline",
        ocultar: !Api.varGlobal("usarAgenda"),
        rolSugerido: "supervisor",
        tareaSugerida: "Ventas",
      },
      {
        ruta: "/erp/facturas",
        label: "Facturas",
        permiso: "Puede crear proyectos",
        icono: "codeSlashOutline",
        rolSugerido: "operador",
        tareaSugerida: "Ventas",
      },
      {
        ruta: "/erp/gastos",
        label: "Gastos",
        permiso: "Puede ver gastos",
        icono: "bagHandleOutline",
        tareaSugerida: "Gastos/compras",
        rolSugerido: "operador",
      },
      /*{
        ruta: "/erp/almacen",
        label: "Almacén",
        permiso: "Puede gestionar almacenes",
        icono: "fileTrayStackedOutline",
        tareaSugerida: "Productos",
        rolSugerido: "operador",
      }, //*/
      {
        ruta: "/erp/reportes",
        label: "Reportes",
        permiso: "Puede ver reportes",
        icono: "statsChartOutline",
        tareaSugerida: "Administración",
        rolSugerido: "supervisor",
      },
      {
        ruta: "/erp",
        label: "",
        permiso: "",
        icono: "listCircleOutline",
      },
      {
        ruta: "/erp/catalogos",
        label: "Catálogos",
        permiso: "Puede editar catálogos",
        icono: "peopleCircleOutline",
        rolSugerido: "supervisor",
        tareaSugerida: "Administración",
      },
    ],
    variables: [
      {
        label: "Certificado CSD",
        name: "uploadcer",
        b64: true,
        omitirHash: false,
      },
      {
        label: "Llave privada CSD",
        name: "uploadkey",
        b64: true,
        omitirHash: false,
      },
      {
        label: "Timbrar en modo producción (timbres reales)",
        name: "timbrarProduccion",
        type: "binary",
      },
      {
        label: "Usar módulo de visualización de agenda",
        name: "moduloAgenda",
        type: "binary",
      },
      {
        label: "Usar módulo de cortes",
        name: "moduloCortes",
        type: "binary",
      },
      {
        label: "Nombre fiscal",
        name: "nombreFiscal",
      },
      {
        label: "Facturama Usuario",
        name: "FacturamaUsuario",
      },
      {
        label: "Facturama Password",
        name: "FacturamaPassword",
      },
      {
        label: "Contraseña el certificado SAT",
        name: "satPassword",
        type: "password",
      },
      {
        label: "Versión de CFDI",
        name: "FacturamaCfdiVersion",
        type: "select",
        options: ["3.3", "4.0"],
      },

      {
        label: "Usar componentes (capacidad de kits de productos)",
        name: "usarComponentes",
        type: "binary",
      },

      {
        label: "Usar PDF de 58mm en punto de venta",
        name: "usarPdf58mm",
        type: "binary",
      },
      {
        label: "Leyenda al final del PDF de 58mm",
        name: "usarPdf58mmLeyenda",
        type: "binary",
        dependeDe: "usarPdf58mm",
      },
      {
        label: "Usar precio sin impuestos",
        name: "precioSinImpuestos",
        type: "binary",
        default: true,
      },
      {
        label: "Bloquear edición de ticket al imprimir",
        name: "ticketBloqueadoAlImprimir",
        type: "binary",
        default: true,
      },
      {
        label: "Usar printserver",
        name: "usarPrintServer",
        type: "binary",
      },
      {
        label: "URL del printserver",
        name: "printServerUrl",
        dependeDe: "usarPrintServer",
        type: "url",
      },
      {
        label: "Printserver imprimir en PDF",
        name: "printServerPdf",
        dependeDe: "usarPrintServer",
        type: "url",
      },

      {
        label: "Usar estados de proyecto",
        name: "usarEstadosDeProyecto",
        type: "binary",
      },
      {
        label: "Guardar PDFs en el servidor",
        name: "guardarPdfEnServidor",
        type: "binary",
      },
      {
        label: "Datos adicionales de PROYECTO (separados por comas)",
        name: "variablesAdicionales",
        type: "text",
      },
      {
        label: "Datos adicionales de PRODUCTO (separados por comas)",
        name: "variablesAdicionalesProducto",
        type: "text",
      },
      {
        label: "Permitir el acceso a clientes",
        name: "accesoAClientes",
        type: "binary",
      },
      {
        label: "Usar precios personalizados para cada cliente",
        name: "preciosPorCliente",
        type: "binary",
      },
    ],
  },
  Gastos: {
    nombre: "Gastos",
    descripcion: "Gestión de gastos y facturas recibidas",
    //icono: "cube",
    icono: "cash",
    esPropio: true,
    tarifaDefault: 399,
    permisosPosibles: [
      "Puede ver gastos",
      "Puede gestionar almacenes",
      "Puede ver reportes",
    ],
    rutaPrincipal: "/erp/gastos",
    menuArbol: [
      {
        ruta: "/erp/gastos",
        label: "Gastos",
        permiso: "Puede ver gastos",
        icono: "bagHandleOutline",
        tareaSugerida: "Gastos/compras",
        rolSugerido: "operador",
      },
      {
        ruta: "/erp/cuentasgastos",
        label: "Cuentas de gastos",
        permiso: "Puede ver gastos",
        icono: "cartOutline",
        tareaSugerida: "Gastos/compras",
        rolSugerido: "administrador",
      },
    ],
    menuRol: {
      cliente: [
        {
          ruta: "/erp/pos",
          label: "Mis ventas y pedidos",
          icono: "fileTrayFullOutline",
        },
        {
          ruta: "/erp/facturas",
          label: "Mis facturas",
          icono: "codeWorking",
        },
      ],
    },
    variables: [],
  },
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol = { ...menuArbol, ...manifiestos };
Api.saveLocal("menuArbol", menuArbol);
export default routes;

/*

Instrucciones:

- Convertir Vue() a defineComponent()
- Cambiar div.ion-page a IonPage
- Importar todos los componentes
- Cambiar todos los íconos a IonIcon, importarlos desde 'ionicons/icons' y exportarlos en data()
- Importar IonMenuButton y cambiarlo a slot="start"
- Cambiar los modelos de Vue() a standalone {...datos,...metodos}
- Filtros depreciados!
  - import {format} from 'date-fns'
  - DD cambia a dd
  - yyyy cambia a yyyy
- Ya que Wanshitong guarda fechas actualas, guardar TODAS las fechas secundarias en el formato nativo de su input
- Todos los input, checkbox y cualquier cosa que use $event DEBE ser importado
- IonContent debe ser importado o no habrá scroll!
- Todos los eventos tendrán que pasarse a los tags de los componentes
- setup() NO puede ser asíncrono!

*/
